import React from "react";
import ReactDOM from "react-dom";
import { Controller } from "stimulus";
import { DebugSessions } from "@/react/components/CustomLogins/DebugSessions";
import { CommunityOnboardingGettingStartedSectionWrapper } from "@/react/components/Layout/CommunityOnboardingGettingStartedSection/CommunityOnboardingGettingStartedSectionWrapper";
import { AnalyticsHeader } from "@/react/components/SettingsApp/AnalyticsHeader";
import { GDPRCookieBanner } from "@circle-react/components/GDPR/GDPRCookieBanner";
import { LiveStreamSettingsV1 as LiveStreamSettings } from "@circle-react/components/SettingsApp/LiveStreamSettings/LiveStreamSettingsV1";
import { MainAppProviders } from "@circle-react/providers";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { VideoPlayer } from "@circle-react-uikit/VideoPlayer";
import { App } from "../react/apps/MainApp";
import { CommentsForm, CommentsList } from "../react/components/Comments";
import {
  CommunitySwitcher,
  CommunitySwitcherWithProviders,
} from "../react/components/CommunitySwitcher";
import { CookieStatus } from "../react/components/CookieStatus";
import { SearchWrapper as SearchV2 } from "../react/components/HeaderV2/Search/SearchWrapper";
import { HomePageV1 } from "../react/components/HomePageV1";
import { HomeRightSidebar } from "../react/components/HomePageV1/HomeRightSidebar";
import { SuggestedSpacesCarousel } from "../react/components/HomePageV1/SuggestedSpacesCarousel";
import { WelcomeBannerV1 } from "../react/components/HomePageV1/WelcomeBanner";
import { InvitationLinkInformation } from "../react/components/InvitationLink/InvitationLinkInformation";
import { LandingPageSettings } from "../react/components/LandingPageSettings";
import { MemberDirectory } from "../react/components/MemberDirectory";
import { NotificationCount } from "../react/components/NotificationCount";
import { Notifications } from "../react/components/Notifications";
import { NotificationsDropdown } from "../react/components/Notifications/NotificationsDropdown";
import PaywallCoupons from "../react/components/PaywallCoupons";
import { PaywallGroups as PaywallGroupsAdmin } from "../react/components/PaywallGroups/Admin";
import { PaywallPrices } from "../react/components/Paywalls";
import { BulkImportTrialPaywallSubscriptionFormRailsView } from "../react/components/Paywalls/Admin/BulkImportTrialPaywallSubscriptionForm";
import { PaywallChargesAdminIndex } from "../react/components/Paywalls/Admin/PaywallChargesAdmin";
import { PaywallSubscriptionsAdminIndex } from "../react/components/Paywalls/Admin/PaywallSubscriptionsAdmin";
import { PaywallsAdmin } from "../react/components/Paywalls/Admin/PaywallsAdmin";
import { Checkout } from "../react/components/Paywalls/Checkout";
import { PostViewList } from "../react/components/Posts/PostViewList";
import { Show } from "../react/components/Posts/Show";
import { OpenQPModal } from "../react/components/QuickPost/OpenQPModal";
import { QPEditPost } from "../react/components/QuickPost/QPEditPost";
import RichTextInput from "../react/components/RichTextInput";
import { SsoInfoMessage } from "../react/components/SettingsApp/Sso/SsoInfoMessage";
import { CreateSpaceButton } from "../react/components/Spaces/CreateSpaceButton";
import { EditSpaceButton } from "../react/components/Spaces/EditSpaceButton";
import { New } from "../react/components/Spaces/New";
import { CommunityMemberSelect } from "../react/components/shared/uikit/CommunityMemberSelect";
import { AppBarWrapper } from "../react/wrappers/AppBarWrapper";

const withMainAppProviders = Component => props =>
  (
    <MainAppProviders>
      <Component {...props} />
    </MainAppProviders>
  );

const COMPONENT_MAP = {
  RichTextInput,
  CommentsForm,
  CommentsList,
  Notifications,
  NotificationsDropdown,
  NotificationCount,
  CommunitySwitcher,
  CommunitySwitcherWithProviders,
  HomePage: HomePageV1,
  WelcomeBanner: WelcomeBannerV1,
  HomeRightSidebar,
  OpenQPModal,
  QPEditPost,
  SuggestedSpacesCarousel,
  LandingPageSettings,
  CookieStatus,
  SpaceCoverImage: () => null,
  CreateSpaceButton,
  EditSpaceButton,
  PostsPostViewList: PostViewList,
  SearchV2,
  "Spaces.New": New,
  "Posts.Show": Show,
  "Events.New": () => null,
  MemberDirectory,
  App: App,
  CommunityMemberSelect,
  PaywallPrices,
  GDPRCookieBanner,
  Checkout,
  "PaywallCoupons.List": PaywallCoupons.List,
  AppBarWrapper,
  CommunityOnboardingGettingStartedSection:
    CommunityOnboardingGettingStartedSectionWrapper,
  StandaloneTipTapBlockEditor: withMainAppProviders(TipTapBlockEditor),

  // Paywalls Settings Sidebar
  PaywallsAdmin,
  PaywallGroupsAdmin,
  PaywallSubscriptionsAdminIndex,
  PaywallChargesAdminIndex,

  BulkImportTrialPaywallSubscriptionFormRailsView,
  InvitationLinkInformation,

  DebugSessions,
  VideoPlayer,
  LiveStreamSettings,
  AnalyticsHeader,
  SsoInfoMessage,
};

export default class extends Controller {
  connect() {
    const { component, props } = this.element.dataset;
    const parsedProps = props ? JSON.parse(props) : {};
    const Comp = COMPONENT_MAP[component];

    if (Comp) {
      ReactDOM.render(<Comp {...parsedProps} />, this.element);
    } else {
      console.error(`Error: invalid component - ${component}`);
    }
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element);
  }
}
